<div>
    <slot />
</div>

<style>
    div {
        flex: 1;
        display: flex;
        justify-content: space-between;
        gap: var(--space-xs);
    }
</style>
