<p><slot /></p>

<style>
    p {
        color: var(--body-text-color-variant);
        font-size: var(--fs-1);
        font-weight: 400;
        text-align: center;
        margin: 0;
        overflow-wrap: anywhere;
    }
</style>
