<script lang="ts">
</script>

<ul>
    <slot />
</ul>

<style lang="scss">
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
</style>
